import { API } from '../../../../../Services/axios';
import { Button, Modal, Row, Space, Table, TableProps } from 'antd';
import { DataType } from '../../../../Admin/AnalyticalReport/AnalyticalReport';
import { ModalProps } from '../../../../../Models/Modal';
import { tableLocaleSettings } from '../../../../Admin/Operators/List/Component/locale';
import { tablePagingSettings } from '../../../../Admin/Operators/List/Component/paging';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';
import ModalTitle from '../../../../../Components/Modals/ModalTitle';

interface DriverPassportModalProps extends ModalProps {
  handleConfirm: (id: string) => void;
  loading: boolean;
}

export const DriverPassport = (props: DriverPassportModalProps) => {
  const { open, handleCancel, handleConfirm, loading } = props;
  const { showErrorModal } = useErrorModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const sizePage = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const [data, setData] = useState({ content: [], totalElements: 0 });
  const [select, setSelect] = useState('');

  useEffect(() => {
    API.get(`/api/v1/registries/additional/drivers?size=${sizePage}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => showErrorModal({ error }));
  }, []);

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: 10,
    },
    {
      key: 'lastname',
      title: 'Фамилия',
      dataIndex: ['driverPassport', 'lastname'],
      sorter: true,
      render: (_, record) => record.driverPassport.lastname,
      width: 50,
    },
    {
      key: 'name',
      title: 'Имя',
      dataIndex: ['driverPassport', 'name'],
      sorter: true,
      render: (_, record) => record.driverPassport.name,
      width: 50,
    },
    {
      key: 'patronymic',
      title: 'Отчество',
      dataIndex: ['driverPassport', 'patronymic'],
      sorter: true,
      render: (_, record) => record.driverPassport.patronymic,
      width: 120,
    },
  ];
  const rowSelection: TableProps<DataType>['rowSelection'] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelect(selectedRows[0].id);
    },
  };

  return (
    <Modal
      open={open}
      title={<ModalTitle level={4} title="Выберите водителя" handleCancel={handleCancel} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32}>
        <Table
          bordered
          rowKey={'id'}
          className="table__organizations"
          loading={loading}
          style={{ width: 600 }}
          scroll={{ x: 655 }}
          columns={columns}
          dataSource={data?.content}
          locale={tableLocaleSettings}
          pagination={tablePagingSettings(data?.totalElements ?? 0, sizePage)}
          rowSelection={{ type: 'radio', ...rowSelection }}
        />
        <div className="modal__footer">
          <Row justify="end">
            <Button size="large" onClick={handleCancel} className="modal__cancel-btn">
              Отменить
            </Button>
            <Button type="primary" size="large" onClick={() => handleConfirm(select)} loading={loading}>
              Подтвердить
            </Button>
          </Row>
        </div>
      </Space>
    </Modal>
  );
};
