import 'dayjs/locale/ru';
import { API } from '../../../../../Services/axios';
import { Button, Col, Collapse, Form, Input, InputRef, Row, Space, Typography, Upload } from 'antd';
import { DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { DriverPassport } from '../../../../Customer/Order/Detail/Component/driver-passport';
import { RcFile } from 'antd/lib/upload';
import { SemiTrailerSelect } from '../../../../Customer/Order/Detail/Component/semitrailer-select';
import { TransportSelect } from '../../../../Customer/Order/Detail/Component/transport-select';
import { getBase64 } from '../../../../../Utils/getBase64';
import { serverDateFormat } from '../../../../../Constants/DateFormats';
import { uploadFilesProps } from '../../../../Customer/Order/Create/Component/upload-files-props';
import { useEffect, useRef, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useForm, useWatch } from 'antd/lib/form/Form';
import DatePicker from '../../../../../Components/DatePicker/DatePicker';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';

export interface IDataAccept {
  driverId: string;
  semiId: string;
  transportId: string;
}
export interface IDataAcceptProps {
  setDataAccept: React.Dispatch<React.SetStateAction<IDataAccept>>;
  dataAccept: IDataAccept;
}

const { Panel } = Collapse;

export const TransportationConfirmationForm = (props: IDataAcceptProps) => {
  const { setDataAccept, dataAccept } = props;
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();
  const inputRef = useRef<InputRef>(null);
  const [items, setItems] = useState([{ brand: '', id: '', truckStateNumber: '' }]);
  const [driverPassport, setDriverPassport] = useState({
    lastname: '',
    name: '',
    patronymic: '',
    series: '',
    number: '',
    orgIssued: '',
    dateIssued: dayjs(new Date()),
    departmentCode: '',
  });
  const [brand, setBrand] = useState('');
  const [truckStateNumber, setTruckStateNumber] = useState('');
  const [semitrailerStateNumber, setSemitrailerStateNumber] = useState('');
  const [driversId, setDriversId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const files = useWatch('files', form);
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);
  const cancelForm = () => {
    setTruckStateNumber('');
    setSemitrailerStateNumber('');
    setDriverPassport({
      lastname: '',
      name: '',
      patronymic: '',
      series: '',
      number: '',
      orgIssued: '',
      dateIssued: dayjs(new Date()),
      departmentCode: '',
    });
    setDataAccept({ driverId: '', semiId: '', transportId: '' });
  };

  const handleConfirm = (id: string) => {
    setDriversId(id);
    setDataAccept({ ...dataAccept, driverId: id });
    handleCancel();
  };

  useEffect(() => {
    API.get(`/api/v1/registries/additional/drivers/${driversId}`)
      .then((response) => {
        setDriverPassport({
          ...response.data.driverPassport,
          dateIssued: dayjs(response.data.driverPassport.dateIssued),
        });
      })
      .catch((error) => showErrorModal({ error }));
  }, [driversId]);

  const normFile = (e: any) => {
    return e?.fileList;
  };
  const addItemDriver = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const base = await getBase64(files[0].originFileObj as RcFile);
    const jsonData = {
      driverPassport: {
        ...driverPassport,
        dateIssued: new Date(driverPassport.dateIssued.format(serverDateFormat)),
      },
      attorney: base.replace(/^data:.+;base64,/, ''),
    };

    API.post(`/api/v1/registries/additional/drivers`, jsonData)
      .then((response) => {
        setDriversId(response.data.id);
        setItems(response.data);
      })
      .catch((error) => showErrorModal({ error }));
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Row gutter={[0, 64]}>
      <Col span={24}>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <Form form={form} colon={false} layout="vertical" className="form">
              <Typography.Title level={5}>Данные по транспорту</Typography.Title>
              <Form.Item>
                <TransportSelect dataAccept={dataAccept} setDataAccept={setDataAccept} />
              </Form.Item>
              <Typography.Title level={5}>Данные по п/п</Typography.Title>
              <Form.Item>
                <SemiTrailerSelect dataAccept={dataAccept} setDataAccept={setDataAccept} />
              </Form.Item>
              <Typography.Title level={5}>Данные по водителю</Typography.Title>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()} style={{ margin: '32px 0' }}>
                Выбрать из справочника
              </Button>

              <Collapse>
                <Panel
                  header={<Typography.Text className="order__info-large">Добавить нового водителя</Typography.Text>}
                  key="1"
                  className="order__adresses"
                >
                  <Row gutter={[24, 0]} justify="space-between" style={{ margin: '16px 0' }}>
                    <Col xxl={8} span={24}>
                      <Input
                        name="lastname"
                        placeholder="Фамилия"
                        ref={inputRef}
                        value={driverPassport?.lastname}
                        onChange={(e) => setDriverPassport({ ...driverPassport, lastname: e.target.value })}
                      />
                    </Col>
                    <Col xxl={8} span={24}>
                      <Input
                        name="name"
                        placeholder="Имя"
                        ref={inputRef}
                        value={driverPassport?.name}
                        onChange={(e) => setDriverPassport({ ...driverPassport, name: e.target.value })}
                      />
                    </Col>
                    <Col xxl={8} span={24}>
                      <Input
                        name="patronymic"
                        placeholder="Отчество"
                        ref={inputRef}
                        value={driverPassport?.patronymic}
                        onChange={(e) => setDriverPassport({ ...driverPassport, patronymic: e.target.value })}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]} justify="space-between" style={{ margin: '16px 0' }}>
                    <Col xxl={12} span={24}>
                      <Input
                        name="series"
                        placeholder="Серия паспорта"
                        ref={inputRef}
                        value={driverPassport?.series}
                        onChange={(e) => setDriverPassport({ ...driverPassport, series: e.target.value })}
                      />
                    </Col>
                    <Col xxl={12} span={24}>
                      <Input
                        name="number"
                        placeholder="Номер паспорта"
                        ref={inputRef}
                        value={driverPassport?.number}
                        onChange={(e) => setDriverPassport({ ...driverPassport, number: e.target.value })}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]} justify="space-between" style={{ margin: '16px 0' }}>
                    <Col span={24}>
                      <Input
                        name="orgIssued"
                        placeholder="Кем выдан"
                        ref={inputRef}
                        value={driverPassport?.orgIssued}
                        onChange={(e) => setDriverPassport({ ...driverPassport, orgIssued: e.target.value })}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]} justify="space-between" style={{ margin: '16px 0' }}>
                    <Col xxl={12} span={24}>
                      <Form.Item name="dateIssued">
                        <DatePicker
                          value={driverPassport?.dateIssued ? driverPassport?.dateIssued : dayjs(new Date())}
                          format={serverDateFormat}
                          size="large"
                          placeholder="Когда выдан"
                          getPopupContainer={(trigger) => trigger.parentElement!}
                          locale={locale}
                          style={{ width: '-webkit-fill-available' }}
                          onChange={(date) =>
                            setDriverPassport({
                              ...driverPassport,
                              dateIssued: dayjs(date),
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xxl={12} span={24}>
                      <Input
                        name="departmentCode"
                        placeholder="Код подразделения"
                        ref={inputRef}
                        value={driverPassport?.departmentCode}
                        onChange={(e) => setDriverPassport({ ...driverPassport, departmentCode: e.target.value })}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]} justify="space-between" style={{ margin: '16px 0' }}>
                    <Col xxl={12} span={24}>
                      <Form.Item
                        label="Доверенность на водителя"
                        name="files"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        className="order__attachments"
                        extra="Для загрузки выберите файл с расширением .pdf, .docx, .xlsx, .txt, .rtf"
                      >
                        <Upload {...uploadFilesProps}>
                          <Button icon={<UploadOutlined />} size="large" disabled={files && files.length === 5}>
                            Загрузить
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Space style={{ margin: '16px 12px' }}>
                    <Button type="default" icon={<PlusOutlined />} onClick={addItemDriver}>
                      Добавить водителя в справочник
                    </Button>
                  </Space>
                </Panel>
              </Collapse>

              <Space style={{ margin: '32px 0' }}>
                <Button type="default" icon={<DeleteOutlined />} onClick={() => cancelForm()}>
                  Очистить все поля
                </Button>
              </Space>
              <DriverPassport
                open={isModalOpen}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                loading={loading}
              />
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
