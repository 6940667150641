import { API } from '../../../../../Services/axios';
import { Agreement } from '../../../../../Models/Agreement';
import { Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { DomEvent } from 'leaflet';
import { File } from '../../../../../Models/Files';
import { OfferStatuses } from '../../../../../Constants/OfferStatuses';
import { OfferType, PriceType } from '../../../../../Models/Offer';
import { TransportationConfirmationForm } from './TransportationConfirmationForm';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { getDayDeclention } from '../../../../../Utils/dayDeclension';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import CancelOfferModal from './CancelOfferModal';
import EditOfferModal from './EditOfferModal';
import OffersHistory from './OfferHistory';
import PricesHistory from './PricesHistory';
import off = DomEvent.off;

interface AgreementInfoProps {
  file: File;
  comment?: string;
  offerValidityTime: number;
  offerId: number;
  price: number;
  dueDatePeriod: number;
  status: string;
  offerData: OfferType;
}
interface IDataAccept {
  driverId: string;
  semiId: string;
  transportId: string;
}

const AgreementInformation = (props: AgreementInfoProps) => {
  const { showErrorModal } = useErrorModal();
  const { file, comment, offerValidityTime, offerId, price, dueDatePeriod, status, offerData } = props;

  const [filesHistory, setFilesHistory] = useState<Agreement[]>();
  const [pricesHistory, setPricesHistory] = useState<PriceType[]>();
  const [offersHistory, setOffersHistory] = useState<OfferType[]>();
  const [dataAccept, setDataAccept] = useState<IDataAccept>({
    driverId: '',
    semiId: '',
    transportId: '',
  });
  const [isOpenedPrices, setIsOpenedPrices] = useState(false);
  const [isOpenedOffers, setIsOpenedOffers] = useState(false);

  const manageOffersCollapse = () => (isOpenedOffers ? setIsOpenedOffers(false) : setIsOpenedOffers(true));
  const managePricesCollapse = () => (isOpenedPrices ? setIsOpenedPrices(false) : setIsOpenedPrices(true));

  useEffect(() => {
    offerId &&
      API.get(`/api/v1/agreement/${offerId}/list`)
        .then((response) => setFilesHistory(response.data))
        .catch((error) => showErrorModal({ error }));
    API.get(`/api/v1/offer/get-history-offers-for-delivery/${offerData.orderId}`)
      .then((response) => setOffersHistory(response.data))
      .catch((error) => showErrorModal({ error }));
  }, [offerId]);

  useEffect(() => {
    API.get(`/api/v1/offer/${offerId}/get-price-history-offer-for-delivery`)
      .then((response) => setPricesHistory(response.data))
      .catch((error) => showErrorModal({ error }));
  }, []);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleCancel = () => setIsModalOpen(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const handleCancelEditOfferModal = () => setIsEditModalOpen(false);
  // const newFileUploader = useRef<HTMLInputElement | null>(null);

  // const uploadNewFile = () => {
  //   newFileUploader!.current!.click();
  // };
  const handleFileUpload = (e) => {
    // const agreementData = {
    //   offerId: offerId,
    // };
    // // const newFile = e.target.files[0];
    //
    // const formData = new FormData();
    // // formData.append('file', newFile);
    // formData.append('json', JSON.stringify(agreementData));
    //
    // // const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    //
    API.put(`/api/v1/offer/accept/${offerId}`, offerData.formEnabled ? dataAccept : {})
      .then((response) => location.reload())
      .catch((error) => showErrorModal({ error }));
  };

  const validityText = (
    <Typography.Text>
      Актуально {offerValidityTime > 0 && 'еще '}
      {<strong>{getDayDeclention(offerValidityTime)}</strong>}
    </Typography.Text>
  );
  const cardTitle = (
    <Row justify="space-between" className="offer__card-title">
      <Col>
        <Typography.Text>
          Предложение действительно {offerValidityTime > 0 && 'еще '}
          <strong>{getDayDeclention(offerValidityTime)}</strong>
        </Typography.Text>
      </Col>
    </Row>
  );

  const offerCard = (
    <Card title={cardTitle}>
      <Row gutter={[0, 24]}>
        <Space size={16} direction="vertical" className="">
          <Col span={24}>
            <Typography.Text className="offer__info-xxl" strong>
              {`${formatPrice(price)} ₽`}
            </Typography.Text>
            <Typography.Text>&emsp;(с учетом НДС)</Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text className="offer__info-xxl" strong>
              {getDayDeclention(dueDatePeriod)}
            </Typography.Text>
            <Typography.Text>&emsp;(срок исполнения)</Typography.Text>
          </Col>

          {comment && (
            <Col span={24}>
              <Typography className="offer__info-large">{comment}</Typography>
            </Col>
          )}
        </Space>
      </Row>
    </Card>
  );
  return (
    <Space size={32} direction="vertical">
      <Card>
        <Col span={24} className="offer__title">
          <Row justify="space-between" align="middle">
            <Typography.Title level={4}>
              {status === OfferStatuses.PRE_AGREEMENT_LOADED
                ? 'Вы подтвердили готовность выполнить заказ'
                : 'Ожидание вашего согласия'}
            </Typography.Title>
            {validityText}
          </Row>
          <Row gutter={[0, 32]}>
            {status !== OfferStatuses.PRE_AGREEMENT_LOADED ? (
              <Col span={22}>
                Заказчик сравнивает предложения перевозчиков и выберет из тех, кто подтвердил возможность и условия
                перевозки.
                <br />
                Вы подтверждаете готовность выполнить заказ?
              </Col>
            ) : (
              <Col span={22}>Идет процесс выбора перевозчика.</Col>
            )}
          </Row>
        </Col>
        {offerCard}
        {/*<Col span={24}>*/}
        {/*  <Space direction="vertical" className={comment ? 'offer__info' : ''} size={12}>*/}
        {/*    <Typography.Text type="secondary" strong>*/}
        {/*      Проект договора*/}
        {/*    </Typography.Text>*/}
        {/*    <Space size={10}>*/}
        {/*      <FileOutlined className="offer__file-icon" />*/}
        {/*      <Typography.Link className="offer__text" href={file.link}>*/}
        {/*        {file.name}*/}
        {/*      </Typography.Link>*/}
        {/*    </Space>*/}
        {/*  </Space>*/}
        {/*</Col>*/}
        <Col span={24}>
          {/*{comment && (*/}
          {/*  <Space direction="vertical" size={12}>*/}
          {/*    <Typography.Text type="secondary" strong>*/}
          {/*      Комментарий к предложению (видимый только заказчику)*/}
          {/*    </Typography.Text>*/}
          {/*    <Typography.Text className="offer__text">{comment}</Typography.Text>*/}
          {/*  </Space>*/}
          {/*)}*/}
          {!offerData.formEnabled && (
            <Space direction="vertical" size={24}>
              <Divider />
              <Typography.Title level={4}>Подтверждение перевозки</Typography.Title>
              <TransportationConfirmationForm setDataAccept={setDataAccept} dataAccept={dataAccept} />
            </Space>
          )}
          <Row className="offer__space-full-width offer__actions" justify="space-between" align="middle">
            {status !== OfferStatuses.PRE_AGREEMENT_LOADED && (
              <>
                <Button size="large" onClick={handleFileUpload} type={'primary'}>
                  Подтверждаю
                </Button>
                <Button size="large" onClick={() => setIsEditModalOpen(true)} type={'primary'}>
                  Изменить цену
                </Button>
              </>
            )}
            <Button size="large" onClick={() => setIsModalOpen(true)}>
              Отозвать предложение
            </Button>
          </Row>
        </Col>
        {pricesHistory && (
          <PricesHistory prices={pricesHistory} isOpened={isOpenedPrices} manageFilesCollapse={managePricesCollapse} />
        )}
        {offersHistory && (
          <OffersHistory offers={offersHistory} isOpened={isOpenedOffers} manageFilesCollapse={manageOffersCollapse} />
        )}
      </Card>

      {/*<Card className="offer__message">*/}
      {/*  <Col span={22} offset={1} className="offer__message-text">*/}
      {/*    <Typography.Text>*/}
      {/*      Если в уже опубликованном проекте договора Вы нашли ошибку, то можно загрузить новый вариант. Заказчик*/}
      {/*      увидит историю изменений а так же Ваши комментарии.*/}
      {/*    </Typography.Text>*/}
      {/*  </Col>*/}
      {/*  <Col span={22} offset={1} className="offer__message-text">*/}
      {/*    <Input.TextArea id="comment" placeholder="Комментарий" className="offer__textarea" rows={2} />*/}
      {/*  </Col>*/}
      {/*  <Col span={22} offset={1}>*/}
      {/*    <Typography.Text onClick={() => uploadNewFile()} className="offer__text-btn">*/}
      {/*      Загрузить новый проект договора*/}
      {/*    </Typography.Text>*/}
      {/*    <input*/}
      {/*      type="file"*/}
      {/*      id="file"*/}
      {/*      accept=".pdf,.jpg,.jpeg"*/}
      {/*      multiple={false}*/}
      {/*      ref={newFileUploader}*/}
      {/*      style={{ display: 'none' }}*/}
      {/*      onChange={handleFileUpload}*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*</Card>*/}

      {/*{filesHistory && filesHistory.length > 1 && (*/}
      {/*  <AgreementsHistory manageFilesCollapse={manageFilesCollapse} isOpened={isOpened} agreements={filesHistory} />*/}
      {/*)}*/}
      <EditOfferModal
        open={isEditModalOpen}
        handleCancel={handleCancelEditOfferModal}
        offerData={offerData}
        priceDisabled={true}
      />
      <CancelOfferModal open={isModalOpen} offerId={offerId} handleCancel={handleCancel} />
    </Space>
  );
};

export default AgreementInformation;
