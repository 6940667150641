import { API } from '../../../../../Services/axios';
import { Button, Divider, Input, InputRef, Select, Space } from 'antd';
import { IDataAcceptProps } from '../../../../Organizer/Order/Detail/Offer/TransportationConfirmationForm';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';

export const TransportSelect = (props: IDataAcceptProps) => {
  const { setDataAccept, dataAccept } = props;
  const { showErrorModal } = useErrorModal();
  const inputRef = useRef<InputRef>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const sizePage = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const [items, setItems] = useState([{ brand: '', id: '', truckStateNumber: '' }]);
  const [brand, setBrand] = useState('');
  const [truckStateNumber, setTruckStateNumber] = useState('');

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    API.post(`/api/v1/registries/additional/transport`, { brand: brand, truckStateNumber: truckStateNumber })
      .then((response) => {
        setDataAccept({ ...dataAccept, transportId: response.data.id });
        items.push(response.data);
      })
      .catch((error) => showErrorModal({ error }));
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    API.get(`/api/v1/registries/additional/transport?page=0&&size=${sizePage}`)
      .then((response) => setItems(response.data.content))
      .catch((error) => showErrorModal({ error }));
  }, []);

  return (
    <Select
      size="large"
      onChange={(e) => setDataAccept({ ...dataAccept, transportId: e })}
      value={dataAccept.transportId}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Введите марку т/с"
              ref={inputRef}
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            />
            <Input
              placeholder="Введите гос номер т/с"
              ref={inputRef}
              value={truckStateNumber}
              onChange={(e) => setTruckStateNumber(e.target.value)}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Добавить т/с в справочник
            </Button>
          </Space>
        </>
      )}
      options={items.map((item) => ({ label: `${item.brand} ${item.truckStateNumber}`, value: item.id }))}
    />
  );
};
